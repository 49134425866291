$mb-10: 10px;
$img_dimension: 70px;
.profile-container {
  padding: 20px;
  .title {
    font-weight: bolder;
    border-bottom-width: 1px;
  }
  .text-right {
    text-align: end;
    margin-right: 20px;
  }

  .content-card {
    font-weight: 500;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .avatar-dimension {
      width: $img_dimension;
      height: $img_dimension;
    }
  }

  @media only screen and (max-width: 767px) {
    .avatar-dimension {
      width: $img_dimension;
      height: $img_dimension;
      margin-bottom: $mb-10;
    }
  }
}

.mt-5 {
  margin-top: 3rem;
}

.text-center {
  text-align: center;
}

.mfa {
  &-title {
    font-size: 30px;
    font-weight: bold;
    margin: 0 16px 24px;
  }
}
.mr-2 {
  margin-right: 0.5rem;
}
